// TrinPartnerModal.tsx
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../styles/TrinPartnerModal.css';

import image1 from '../../assets/trinpartners/trin1.png';
import image2 from '../../assets/trinpartners/trin2.png';
import image3 from '../../assets/trinpartners/trin3.png';

interface TrinPartnerModalProps {
    show: boolean;
    handleClose: () => void;
}

const TrinPartnerModal: React.FC<TrinPartnerModalProps> = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header>
                <Button className="custom-close" onClick={handleClose}>
                    &times;
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-content-container">
                    <h2>- TrinPartners -</h2>
                    <p className="description">Création d'interface de jonction API/Database pour accès aux données par ERP - QUANTRIX</p>
                    <p className="techno">PHP - MySQL</p>
                    <div className="images-container">
                        <p className="mission-description">
                            Dans le cadre d'une mission pour l'entreprise Enedis, Trin-Partners, entreprise de développement sur progiciel de modélisation financière Quantrix, s'est trouvé dans le besoin d'un accès à des jeux de données accessibles par appel API REST. Dans ce cadre, j'ai développé une interface de jonction API/DB afin de permettre la lecture des données en DB par le progiciel.
                        </p>
                        <div className="image-single">
                            <img src={image3} alt="Feature 3" className="modal-image" />
                        </div>
                        <div className="image-pair">
                            <img src={image1} alt="Feature 1" className="modal-image" />
                            <img src={image2} alt="Feature 2" className="modal-image" />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TrinPartnerModal;
