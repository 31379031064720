import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import videoSource from '../assets/bg.mp4';
import './styles/Home.css';

const Home: React.FC = () => {
    const scrollToNextSection = () => {
        document.getElementById('cv-title')?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div id="home-container" className="home-container">
            <video autoPlay muted className="background-video">
                <source src={videoSource} type="video/mp4" />
            </video>
            <TypeAnimation
                sequence={[
                    'Développeur Web',
                    2000,
                    '',
                    500,
                    'Concepteur d\'applications',
                    2000,
                    '',
                    500,
                    'Développeur fullstack',
                    2000,
                ]}
                wrapper="span"
                speed={40}
                deletionSpeed={30}
                repeat={Infinity}
                className="animated-text"
            />

            <div className="separator" />

            <h1 className="main-title">
                Pierre-Olivier Caussarieu
            </h1>

            <ArrowCircleDownIcon fontSize='large' className="scroll-arrow" onClick={scrollToNextSection} />
        </div>
    );
};

export default Home;
