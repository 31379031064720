import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './styles/Projets.css';
import GourmaiModal from './modals/GourmaiModal';
import WildwatchModal from './modals/WildwatchModal';
import gourmaiImg from '../assets/gourmai.png';
import wildwatchImg from '../assets/wildwatch.png';
import trinImg from '../assets/trin.png';
import aixelliumImg from '../assets/aixellium.png';
import TrinPartnerModal from './modals/TrinPartnerModal';
import AixelliumModal from './modals/AixelliumModal';

const Projets: React.FC = () => {
    const [showModalGourmai, setShowModalGourmai] = useState(false);
    const [showModalWildwatch, setShowModalWildwatch] = useState(false);
    const [showModalTrinPartner, setShowModalTrinPartner] = useState(false);
    const [showModalAixellium, setShowModalAixellium] = useState(false);



    const projets = [
        {
            title: 'GourmAI',
            description: 'Application iOS/Android de génération de recettes de cuisine par intelligence artificielle',
            imgSrc: gourmaiImg,
            onClick: () => setShowModalGourmai(true)
        },
        {
            title: 'WildWatch (En cours)',
            description: 'Application iOS/Android axée sur la réduction des dommages aux cultures et infrastructures causés par les espèces animales classées nuisibles',
            imgSrc: wildwatchImg,
            onClick: () => setShowModalWildwatch(true)

        },
        {
            title: 'Solution de jonction API/Database',
            description: 'Solution de jonction API/Database pour accès par ERP',
            imgSrc: trinImg,
            onClick: () => setShowModalTrinPartner(true)
        },
        {
            title: 'Aixellium-Conseils',
            description: 'Création d\'outil de démarchage visant à proposer aux indépendants et PME des audits et une prise en main de leur SEO',
            imgSrc: aixelliumImg,
            onClick: () => setShowModalAixellium(true)
        }
    ];

    return (
        <Container className="projets-container my-5" id="projets">
            <h2 className="title text-center">Réalisations</h2>
            <Row className="mt-4">
                {projets.map((projet, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card className="h-100" onClick={projet.onClick}>
                            <Card.Img variant="top" src={projet.imgSrc} alt={projet.title} />
                            <Card.Body>
                                <Card.Title className="card-title">{projet.title}</Card.Title>
                                <Card.Text>{projet.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <GourmaiModal show={showModalGourmai} handleClose={() => setShowModalGourmai(false)} />
            <WildwatchModal show={showModalWildwatch} handleClose={() => setShowModalWildwatch(false)} />
            <TrinPartnerModal show={showModalTrinPartner} handleClose={() => setShowModalTrinPartner(false)} />
            <AixelliumModal show={showModalAixellium} handleClose={() => setShowModalAixellium(false)} />

        </Container>
    );
};

export default Projets;
