// src/App.tsx
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './components/Main';

function App() {
    return (
        <div style={{ height: '100vh', overflowY: 'auto', position: 'relative' }}>
            <Router>
                <Routes>
                    <Route path="/" element={<Main />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
