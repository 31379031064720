// src/components/MainPage.tsx
import React from 'react';
import Home from './Home';
import CV from './CV';
import Navbar from './Navbar';
import Projets from './Projets';
import Technologies from './Technologies';
const Main: React.FC = () => {
    return (
        <div>
            <Navbar />
            <Home />
            <CV />
            <Projets />
            <Technologies />
        </div>
    );
};

export default Main;
