import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../styles/WildwatchModal.css';
import { Document, Page, pdfjs } from 'react-pdf';
import pdf from '../../assets/ww.pdf';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface WildwatchModalProps {
    show: boolean;
    handleClose: () => void;
}

const WildwatchModal: React.FC<WildwatchModalProps> = ({ show, handleClose }) => {
    const [numPages, setNumPages] = useState(0);
    const [pageWidth, setPageWidth] = useState(window.innerWidth * 0.5);

    useEffect(() => {
        const handleResize = () => {
            setPageWidth(window.innerWidth <= 768 ? window.innerWidth : window.innerWidth * 0.5);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header>
                <Button className="custom-close" onClick={handleClose}>
                    &times; {/* Unicode pour le symbole de croix */}
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="pdf-container">
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={pageWidth}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ))}
                    </Document>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default WildwatchModal;
