// AixelliumModal.tsx
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../styles/AixelliumModal.css';

import image1 from '../../assets/aixellium/aix1.png';
import image2 from '../../assets/aixellium/aix2.png';
import image3 from '../../assets/aixellium/aix3.png';
import image4 from '../../assets/aixellium/aix4.png';

interface AixelliumModalProps {
    show: boolean;
    handleClose: () => void;
}

const AixelliumModal: React.FC<AixelliumModalProps> = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header>
                <Button className="custom-close" onClick={handleClose}>
                    &times;
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-content-container">
                    <h2>- Aixellium -</h2>
                    <p className="description">Développement d'une plateforme de démarchage par email dans le but d'informer les prospects sur la qualité de leur référencement web.</p>
                    <p className="techno">PHP - MySQL - Brevo - Retool</p>
                    <div className="images-container">

                        <div className="image-pair">
                            <img src={image1} alt="Feature 1" className="modal-image" />
                            <img src={image2} alt="Feature 2" className="modal-image" />
                        </div>
                        <div className="image-pair">
                            <img src={image3} alt="Feature 3" className="modal-image" />
                            <img src={image4} alt="Feature 4" className="modal-image" />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AixelliumModal;
