// Technologies.tsx
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './styles/Technologies.css';

import reactImg from '../assets/react.png';
import reactNativeImg from '../assets/react-native.png';
import typescriptImg from '../assets/typescript.png';
import javascriptImg from '../assets/javascript.png';
import firebaseImg from '../assets/firebase.png';
import phpImg from '../assets/php.png';
import symfonyImg from '../assets/symfony.png';
import flutterImg from '../assets/flutter.png';
import expoImg from '../assets/expo.png';
import sqlImg from '../assets/sql.png';
import javaImg from '../assets/java.png';
import dockerImg from '../assets/docker.png';

const technologies = [
    { title: 'React', imgSrc: reactImg },
    { title: 'React Native', imgSrc: reactNativeImg },
    { title: 'TypeScript', imgSrc: typescriptImg },
    { title: 'JavaScript', imgSrc: javascriptImg },
    { title: 'Firebase', imgSrc: firebaseImg },
    { title: 'PHP', imgSrc: phpImg },
    { title: 'Symfony', imgSrc: symfonyImg },
    { title: 'Flutter', imgSrc: flutterImg },
    { title: 'Expo', imgSrc: expoImg },
    { title: 'SQL', imgSrc: sqlImg },
    { title: 'Java', imgSrc: javaImg },
    { title: 'Docker', imgSrc: dockerImg },
];

const Technologies: React.FC = () => {
    return (
        <Container className="technologies-container my-5" id="technologies">
            <h2 className="title text-center">Technologies</h2>
            <Row className="mt-4">
                {technologies.map((tech, index) => (
                    <Col xs={6} md={3} key={index} className="mb-4"> {/* xs={6} for 2 cards per row on mobile */}
                        <Card className="h-100 tech-card">
                            <Card.Img variant="center" src={tech.imgSrc} alt={tech.title} className="card-img-top" />
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Technologies;
