import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import './styles/CV.css';
import { School, Code, Language, Work, Construction } from '@mui/icons-material';

// Importation des images
import reactImg from '../assets/react.png';
import typescriptImg from '../assets/typescript.png';
import firebaseImg from '../assets/firebase.png';

const CV: React.FC = () => {
    return (
        <div>
            <div className="portfolio-header" id="cv-title">
                <span>Portfolio réalisé en </span>
                <img src={reactImg} alt="React" className="icon" />
                <span>, avec </span>
                <img src={typescriptImg} alt="TypeScript" className="icon" />
                <span> et hébergé sur </span>
                <img src={firebaseImg} alt="Firebase" className="icon" />
            </div>
            <div className="paper-background">
                <Container className="cv-container">
                    <Row className="justify-content-center mt-4">
                        <Col md={8}>
                            <section>
                                <h2 className="section-title text-left"><Work /> EXPÉRIENCES PROFESSIONNELLES</h2>
                                <ListGroup.Item><strong>CRÉATION D'APPLICATION DE GÉNÉRATION DE RECETTES PAR INTELLIGENCE ARTIFICIELLE - 2024</strong> &gt; Conception et développement d'une application de génération de recettes de cuisine par IA.</ListGroup.Item>
                                <ListGroup.Item><strong>DÉVELOPPEUR D’APPLICATION MOBILE - 2023</strong> &gt; Conception et développement d'une application mobile axée sur la réduction des dommages aux cultures et infrastructures causés par les espèces animales classées nuisibles.</ListGroup.Item>
                                <ListGroup.Item><strong>CRÉATION D’APPLICATION D’INTERFACE JSON/REST &lt;&gt; BASE DE DONNÉES - 2023</strong> &gt; Trin-Partners</ListGroup.Item>
                                <ListGroup.Item><strong>AUDITEUR EN SEO - Création d’entreprise - 2022</strong> &gt; Aixellium-Conseils &gt; <a id="aixellium-link" href='https://www.aixellium-conseils.com/'>https://www.aixellium-conseils.com/</a></ListGroup.Item>
                            </section>
                            <div className="section-separator"></div> {/* Separator added */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <section className="mt-4">
                                <h2 className="section-title"><Construction /> COMPÉTENCES</h2>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>Développement Web/Mobile/Logiciel</ListGroup.Item>
                                    <ListGroup.Item>Conception d'applications</ListGroup.Item>
                                    <ListGroup.Item>Gestion de Firebase et technologies Cloud</ListGroup.Item>
                                    <ListGroup.Item>Déploiement d'applications iOS/Android</ListGroup.Item>
                                    <ListGroup.Item>DevOps</ListGroup.Item>
                                    <ListGroup.Item>Application des techniques de cybersécurité</ListGroup.Item>
                                    <ListGroup.Item>Mise en place et gestion réseau</ListGroup.Item>
                                </ListGroup>
                            </section>
                            <div className="section-separator mobile-only"></div>
                            <section className="mt-4">
                                <h2 className="section-title"><School /> FORMATIONS</h2>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><strong>CDA - Concepteur développeur d’applications - 2023/2024</strong> &gt; Vannes - GRETA Bretagne Sud</ListGroup.Item>
                                    <ListGroup.Item><strong>BTS SIO - SLAM - 2022/2023</strong> &gt; Vannes - GRETA Bretagne Sud</ListGroup.Item>
                                </ListGroup>
                            </section>
                            <div className="section-separator mobile-only"></div>
                        </Col>

                        <Col md={6} className="desktop-border-left">
                            <section className="mt-4">
                                <h2 className="section-title"><Code /> LANGAGES ET COMPÉTENCES INFORMATIQUES</h2>
                                <h4>Langages</h4>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>JavaScript/TypeScript, PHP, Java</ListGroup.Item>
                                </ListGroup>
                                <h4 className="mt-3">Frameworks</h4>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>React, React Native, Flutter, Symfony</ListGroup.Item>
                                </ListGroup>
                                <h4 className="mt-3">Data</h4>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>Firebase, SQL, PostgreSQL</ListGroup.Item>
                                </ListGroup>
                                <h4 className="mt-3">DevOps</h4>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>GitHub, Docker</ListGroup.Item>
                                </ListGroup>
                            </section>
                            <div className="section-separator mobile-only"></div>
                            <section className="mt-4">
                                <h2 className="section-title"><Language /> LANGUES</h2>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><strong>Anglais</strong>: C2.2-Natif</ListGroup.Item>
                                    <ListGroup.Item><strong>Espagnol</strong>: B1-Intermédiaire</ListGroup.Item>
                                </ListGroup>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default CV;
