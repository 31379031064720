import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../styles/GourmaiModal.css';

import recipeImage from '../../assets/gourmai/recipe.jpg';
import recetteImage from '../../assets/gourmai/recette.jpg';
import mainImage from '../../assets/gourmai/main.jpg';
import blogImage from '../../assets/gourmai/blog.jpg';
import playstoreIcon from '../../assets/playstore.png';
import appstoreIcon from '../../assets/appstore.png';

interface GourmaiModalProps {
    show: boolean;
    handleClose: () => void;
}

const GourmaiModal: React.FC<GourmaiModalProps> = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header>
                <Button className="custom-close" onClick={handleClose}>
                    &times;
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-content-container">
                    <h2>- GourmAI -</h2>
                    <p className="description">Application de génération de recettes de cuisines par intelligence artificielle.</p>
                    <p className="description">
                        <a href='https://github.com/gpocil/GourmAI' target='_blank' rel="noreferrer">https://github.com/gpocil/GourmAI</a>
                    </p>

                    <div className="icon-container">
                        <a href="https://play.google.com/store/apps/details?id=com.gpocil.GourmAI" target="_blank" rel="noreferrer" className="icon-link">
                            <img src={playstoreIcon} alt="Play Store" className="icon-image" />
                        </a>
                        <a href="https://apps.apple.com/us/app/gourmai-chef-cuisinier-ia/id6499013199" target="_blank" rel="noreferrer" className="icon-link">
                            <img src={appstoreIcon} alt="App Store" className="icon-image" />
                        </a>
                    </div>

                    <p className="techno">React Native - Typescript - OpenAI - FireBase - Expo EAS</p>

                    <div className="images-container">
                        <div className="image-pair">
                            <img src={mainImage} alt="Main" className="modal-image" />
                            <img src={recipeImage} alt="Recipe" className="modal-image" />
                        </div>
                        <div className="image-pair">
                            <img src={recetteImage} alt="Recette" className="modal-image" />
                            <img src={blogImage} alt="Blog" className="modal-image" />
                        </div>
                        <div className="separator-horizontal" />
                        <div className="separator-vertical" />
                    </div>
                    <p className="description"></p>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default GourmaiModal;
