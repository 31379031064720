// Navbar.tsx
import React, { useState, useEffect, useRef } from 'react';
import './styles/Navbar.css';

const Navbar: React.FC = () => {
    const [activeLink, setActiveLink] = useState('');
    const [menuOpen, setMenuOpen] = useState(false); // Ajouter un état pour suivre l'état du menu hamburger
    const observer = useRef<IntersectionObserver>();

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Trigger when 50% of the section is visible
        };

        observer.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveLink(entry.target.id);
                }
            });
        }, options);

        const homeSection = document.getElementById('home-container');
        const cvSection = document.getElementById('cv-title');
        const projets = document.getElementById('projets');
        const technologies = document.getElementById('technologies');

        if (homeSection) observer.current.observe(homeSection);
        if (cvSection) observer.current.observe(cvSection);
        if (projets) observer.current.observe(projets);
        if (technologies) observer.current.observe(technologies);

        return () => {
            if (homeSection) observer.current.unobserve(homeSection);
            if (cvSection) observer.current.unobserve(cvSection);
            if (projets) observer.current.unobserve(projets);
            if (technologies) observer.current.unobserve(technologies);
        };
    }, []);

    const handleClick = (id: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        const section = document.getElementById(id);
        section?.scrollIntoView({ behavior: 'smooth' });
        setActiveLink(id);
        setMenuOpen(false); // Fermer le menu hamburger après avoir cliqué sur un élément
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Inverser l'état du menu hamburger
    };

    return (
        <nav className="navbar">
            <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="menu-icon-bar"></div>
                <div className="menu-icon-bar"></div>
                <div className="menu-icon-bar"></div>
            </div>
            <ul className={`menu ${menuOpen ? 'open' : ''}`}>
                <li id="home-link" className={activeLink === 'home-container' ? 'active' : ''}>
                    <a href="#home-container" onClick={handleClick('home-container')}>Accueil</a>
                </li>
                <li className={activeLink === 'cv-title' ? 'active' : ''}>
                    <a href="#cv-title" onClick={handleClick('cv-title')}>CV</a>
                </li>
                <li className={activeLink === 'projets' ? 'active' : ''}>
                    <a href="#projets" onClick={handleClick('projets')}>Projets</a>
                </li>
                <li className={activeLink === 'technologies' ? 'active' : ''}>
                    <a href="#technologies" onClick={handleClick('technologies')}>Technologies</a>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
